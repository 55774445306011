<template>
  <div class="d-flex flex-column flex-fill white pb-5">
    <h1 class="text-center">Recibo de Abertura de Caixa</h1>
    <h4 class="text-center my-5">Fisiopower</h4>
    <div class="d-flex justify-center my-5">
      <table>
        <tr>
          <th>Data e Hora</th>
          <td>{{ box.dt_open | moment("DD/MM/YYYY HH:mm:ss") }}</td>
        </tr>
        <tr>
          <th>Nº Caixa</th>
          <td>{{ box.id }}</td>
        </tr>
        <tr>
          <th>Valor de Abert.</th>
          <td>{{ box.open_value | money }}</td>
        </tr>
        <tr>
          <th>Vendedor</th>
          <td>{{ box.seller.first_name }}</td>
        </tr>
      </table>
    </div>
    <div class="signature mt-15">
      <span>{{ box.seller.first_name }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OpenBox",
  beforeMount() {
    if (!this.box) this.$router.push({ name: "OpenBox" });
  },
  computed: {
    ...mapGetters(["box"]),
  },
};
</script>

<style scoped>
table {
  width: 300px;
}
th {
  width: 120px;
}
td {
  text-align: right;
}
table,
th,
td {
  border-spacing: 0;
  border: 1px solid #333;
}

.signature {
  display: flex;
  justify-content: center;
  text-align: center;
}

.signature span {
  width: 300px;
  border-top: 1px solid #000;
}
</style>
